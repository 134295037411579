<template>
  <div class="p-text-input" @click="focusInput(name)">
    <div class="p-text-input__cover" :error="errorState" :inputDisabled="disabled">
      <span v-if="$slots.prepend" class="p-text-input__prepend">
        <slot name="prepend" />
      </span>
      <div class="p-text-input__inner">
        <input
          :id="name"
          v-model="value"
          v-bind="{ ...$attrs, ...(props as InputHTMLAttributes) }"
          class="p-text-input__field"
          :data-test="testAttribute"
          autocomplete="off"
          required
          @focus="handleFocus"
          @blur="handleBlur(value)"
          @input="$emit('on-input', value)"
          @change="$emit('on-change', value)"
        />
        <label
          :for="name"
          class="p-text-input__label"
          :defaultPosition="!value && disabled ? true : false"
          v-text="label"
        />
      </div>
      <span v-if="$slots.append" class="p-text-input__append">
        <slot name="append" />
      </span>
      <span v-if="$slots.appendAnimation" class="p-text-input__append--animation">
        <slot name="appendAnimation" />
      </span>
    </div>
    <p-error-message v-if="errorState" :name="name" class="p-text-input--error" />
  </div>
</template>

<script setup lang="ts">
import type { InputHTMLAttributes } from 'vue'
import { useField, ErrorMessage as PErrorMessage } from 'vee-validate'

const emit = defineEmits<{
  'on-change': [value: any]
  'on-input': [value: any]
  'on-focus': [value: boolean]
  'on-blur': [value: string | number]
}>()

interface TextInputState {
  onFocus: boolean
  onBlur: boolean
}

const textInputState = reactive<TextInputState>({
  onFocus: false,
  onBlur: false,
})
const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  placeholder: {
    type: String,
    default: null,
  },
  maxlength: {
    type: Number,
    default: null,
  },
  type: {
    type: String,
    default: 'text',
  },
  step: {
    type: [String, Number],
    default: 1,
  },
  inputmode: {
    type: String,
    default: 'text',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  modelValue: {
    type: [String, Number],
    default: '',
  },
})

const testAttribute = dataTest(props.name as string)
const name = toRef(props, 'name')
const modelValue = toRef(props, 'modelValue')

const { value, meta, validate } = useField(name, undefined, {
  initialValue: modelValue.value,
})

const errorState = computed<boolean>(() => {
  return !meta.valid && meta.validated
})

const focusInput = (name: string) => {
  const el = document.getElementById(name) as HTMLElement
  el.focus()
}

const handleFocus = () => {
  textInputState.onFocus = true
  textInputState.onBlur = false
  emit('on-focus', textInputState.onFocus)
}

const handleBlur = (value: string | number) => {
  textInputState.onBlur = true
  textInputState.onFocus = false
  emit('on-blur', value)
  validate()
}

watch(
  () => modelValue.value,
  () => (value.value = modelValue.value),
)
</script>
